import { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Table } from '@consta/uikit/Table'
import { AutoComplete } from '@consta/uikit/AutoCompleteCanary'
import { TextField } from '@consta/uikit/TextField'
import { Combobox } from '@consta/uikit/Combobox'

import { IconSearchStroked } from '@consta/icons/IconSearchStroked'

import {
  itemsSelectStatusReport,
  itemsSelectStatusReportEditor,
} from 'shared/constants/itemsSelectStatusReport'
import { itemsSelectReportingPeriod } from 'shared/constants/itemsSelectReportingPeriod'
import { itemsAutoCompleteMunicipality } from 'shared/constants/itemsAutoCompleteMunicipality'

import { FilteringOptionsForReport } from 'shared/types/FilteringOptionsForReport'

import styles from './ReportTable.module.css'
import { StatusAuthData } from '../../../shared/types/statusAuthData'
import { getQuarterAsGreek, getQuarterAsGreekNow } from '../../../shared/utils/getQuarter'
import { ReportStatusEnumToRu } from '../../../shared/types/reportStatusEnum'
import { useInView } from 'react-intersection-observer'
import { getListOfReport } from '../../../shared/Api/reports'
import {
  filterParamsToRequestParams,
  getParamsFromUrl,
  getReportNavigateURL,
  structureReport,
} from '../../../shared/utils/helper'
import { UserRolesEnum } from '../../../shared/enums/UserRole'
import AlertCard from '../../../components/Dashboard/AlertCard/AlertCard'
import { TypeOfAlertEnum } from '../../../shared/types/typeOfAlertEnum'

interface ReportProps {
  authData: StatusAuthData
}

export const ReportTable: FC<ReportProps> = ({ authData }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const quarter = getQuarterAsGreek()
  const thisQuarter = getQuarterAsGreekNow()
  const queryParams = new URLSearchParams(location.search)
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(20)
  const [firstLoad, setFirstLoad] = useState(true)
  const [reportList, setReportList] = useState<
    {
      id: string
      municipal_entity: string
      status: string
      reportingPeriod: string
      dateDispatch: string
      type: 'quarter' | 'year'
    }[]
  >([])
  const [filterParameters, setFilterParameters] = useState<FilteringOptionsForReport>({
    municipality: null,
    status: null,
    search: null,
    reportingPeriod: null,
  })
  const { ref, inView } = useInView({
    threshold: 0.1,
  })
  useEffect(() => {
    if (!authData.isLoaded) {
      return
    }
    const params = getParamsFromUrl(queryParams)
    const statusSelector =
      authData.role === UserRolesEnum.EDITOR
        ? itemsSelectStatusReportEditor
        : itemsSelectStatusReport
    if (firstLoad) {
      setFilterParameters({
        ...params,
        status: params.status ? statusSelector.find(el => params.status === el.statusFilter) : null,
        reportingPeriod: params.reportingPeriod ? { label: params.reportingPeriod } : null,
      })
    }
  }, [firstLoad, authData])
  useEffect(() => {
    if (inView && reportList.length + limit < total) {
      setOffset(reportList.length)
    } else if (inView && total - reportList.length > 0) {
      setLimit(total - reportList.length)
      setOffset(reportList.length)
    }
  }, [inView, reportList])

  useEffect(() => {
    if (!authData.isLoaded) {
      return
    }
    const paramsForReq = filterParamsToRequestParams({
      ...filterParameters,
      status: filterParameters?.status?.statusFilter,
      reportingPeriod: filterParameters?.reportingPeriod?.label,
    })
    getListOfReport({ ...paramsForReq, limit, offset }).then(reportResponse => {
      let reports = structureReport(reportResponse.data, authData.role, offset)
      reports = reports.map((report, index) => ({
        ...report,
        serial: offset + index + 1,
      }))
      
      if (firstLoad) {
        setFirstLoad(false)
      }
      setTotal(reportResponse.total)
      limit === 20 && offset === 0
        ? setReportList(reports)
        : setReportList([...reportList, ...reports])
    })
  }, [filterParameters, limit, offset, authData])
  
  const handleClickRow = ({ id }: { id: string | undefined }): void => {
    const type = reportList.find((obj: any) => obj.id === id)?.status
    if (type === ReportStatusEnumToRu.APPROVED || type === ReportStatusEnumToRu.AUTO_APPROVED) {
      navigate(`/reports/${id}`)
    } else {
      navigate(`/reports/${id}/edit`)
    }
  }
  const handleChangeFilterParams = (value: any, name: any) => {
    setFilterParameters(prev => ({
      ...prev,
      [name]: value,
    }))
    setLimit(20)
    setOffset(0)
    navigate(
      getReportNavigateURL({
        ...filterParameters,
        [name]: value,
      })
    )
  }
  const columns: any = [
    {
      title: '№',
      accessor: 'serial',
    },
    ...(authData?.role !== UserRolesEnum.DEPUTY
      ? [
          {
            title: 'Муниципальное образование ',
            accessor: 'municipal_entity',
          },
        ]
      : []),
    {
      title: 'Отчетный промежуток',
      accessor: 'reportingPeriod',
    },
    {
      title: 'ID',
      accessor: 'id',
    },
    {
      title: 'Статус',
      accessor: 'status',
      renderCell: (row: any) => (
        <div
          style={{
            color: row.color,
          }}
        >
          {row.status}
        </div>
      ),
    },
    {
      title: 'Дата отправки',
      accessor: 'dateDispatch',
    },
  ]

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerContainer}>
        <h3 className={styles.title}>Отчетность</h3>
        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
          {authData?.role === UserRolesEnum.DEPUTY && (
            <AlertCard
              typeOfAlert={TypeOfAlertEnum.CREATE_QUARTER_REPORT}
              dashboardData={{
                quarter,
                isButtonDisabled: !authData.canCreateReport,
              }}
            />
          )}
          {authData?.role === UserRolesEnum.DEPUTY && (
            <AlertCard
              typeOfAlert={TypeOfAlertEnum.SHOW_QUARTER_REPORT}
              dashboardData={{
                quarter: thisQuarter,
              }}
            />
          )}
          {authData?.role === UserRolesEnum.DEPUTY && (
            <AlertCard typeOfAlert={TypeOfAlertEnum.SHOW_YEAR_REPORT} />
          )}
        </span>
      </div>
      <div className={styles.filtrationContainer}>
        {authData?.role === UserRolesEnum.EDITOR && (
          <>
            <TextField
              width='full'
              onChange={e => handleChangeFilterParams(e.value, 'search')}
              value={filterParameters.search}
              placeholder='ФИО сотрудника'
              leftSide={IconSearchStroked}
              name='search'
            />
            <AutoComplete
              type='text'
              placeholder='МО'
              value={filterParameters.municipality}
              items={itemsAutoCompleteMunicipality}
              onChange={e => handleChangeFilterParams(e.value, 'municipality')}
              width='full'
              name='municipality'
            />
          </>
        )}
        <Combobox
          placeholder='Отчетный промежуток'
          items={itemsSelectReportingPeriod}
          value={filterParameters.reportingPeriod}
          onChange={e => handleChangeFilterParams(e.value, 'reportingPeriod')}
        />
        <Combobox
          placeholder='Статус'
          items={
            authData.role === UserRolesEnum.EDITOR
              ? itemsSelectStatusReportEditor
              : itemsSelectStatusReport
          }
          value={filterParameters.status}
          onChange={e => handleChangeFilterParams(e.value, 'status')}
        />
      </div>
      {reportList && (
        <div>
          <Table
            rows={reportList}
            columns={columns}
            zebraStriped='even'
            borderBetweenRows
            borderBetweenColumns
            activeRow={{ id: undefined, onChange: handleClickRow }}
          />
          <div style={{ height: '2px', width: '100%' }} ref={ref}></div>
        </div>
      )}
    </div>
  )
}
